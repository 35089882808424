<template>
	<div class="container">
		<div class="main">
			<div class="main-title">
				<span>用户登陆</span>
			</div>
			<div class="main-content" :class=" ( activeName == 'chat' && !qrcode_status ) ? 'chat' : '' " >
				<div class="main-content-box">
					<span class="main-content-item" :class="{ 'class-select' : activeName == 'chat' }" @mouseover="handleClick('chat')">微信扫码</span>
					<span class="main-content-border" v-if="registerConfig.register && registerConfig.register.indexOf('mobile') != -1"></span>
					<span class="main-content-item" v-if="registerConfig.register && registerConfig.register.indexOf('mobile') != -1" :class="{ 'class-select' : activeName == 'code' }" @mouseover="handleClick('code')">短信登录</span>
					<span class="main-content-border" v-if="registerConfig.register && registerConfig.register.indexOf('username') != -1"></span>
					<span class="main-content-item" v-if="registerConfig.register && registerConfig.register.indexOf('username') != -1" :class="{ 'class-select' : activeName == 'account' }" @mouseover="handleClick('account')">密码登录</span>
				</div>
				<!-- 微信扫码登录 -->
				<div class="main-login-chat" v-show="activeName == 'chat'">
					<div v-show="!qrcode_status">
						<img class="code-img" :src="$util.img(wechat_qrcode)" alt="">
						<div class="code-content">打开微信扫一扫登录</div>
					</div>
					<div class="qrcode_area" v-show="qrcode_status">
						<div class="qrcode_tips">扫码成功</div>
						<div class="main-login-form" >
							<el-form :model="formData" :rules="mobileRules" ref="mobileRuleForm"  >
								<el-form-item  prop="mobile" :show-message="false">
									<el-input  v-model="formData.mobile" placeholder="手机号"></el-input>
								</el-form-item>
								<el-form-item  prop="vercode" v-if="shop_pc_reception_login == 1" :show-message="false">
									<el-input autocomplete="off" v-model="formData.vercode" placeholder="验证码" maxlength="4"></el-input>
									<span class="right_part">
										<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
									</span>
								</el-form-item>
								<el-form-item prop="dynacode" :show-message="false">
									<el-input v-model="formData.dynacode" placeholder="动态码"></el-input>
									<span class="right_part" @click="sendMobileCode('mobileRuleForm')">{{ dynacodeData.codeText }}</span>
								</el-form-item>
								<el-form-item class="main-login-form-checkbox">
									<el-checkbox v-model="formData.checked">同意《用户协议》和《隐私政策》</el-checkbox>
								</el-form-item>
								<el-form-item class="main-login-form-button">
									<div class="button_flex" @click="mobileLogin('mobileRuleForm','wechat')">登录<span class="line"></span>注册</div>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<!-- 短信登录 -->
				<div class="main-login-form" v-show="activeName == 'code'" >
					<el-form :model="formData" :rules="mobileRules" ref="mobileRuleForm" >
						<el-form-item  prop="mobile" :show-message="false">
							<el-input  v-model="formData.mobile" placeholder="手机号"></el-input>
						</el-form-item>
						<el-form-item  prop="vercode" v-if="shop_pc_reception_login == 1" :show-message="false">
							
							<el-input autocomplete="off" v-model="formData.vercode" placeholder="验证码" maxlength="4"></el-input>
							<span class="right_part">
								<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
							</span>
						</el-form-item>
						<el-form-item prop="dynacode" :show-message="false">
							<el-input v-model="formData.dynacode" placeholder="动态码"></el-input>
							<span class="right_part" @click="sendMobileCode('mobileRuleForm')">{{ dynacodeData.codeText }}</span>
						</el-form-item>
						<el-form-item class="main-login-form-checkbox tips" >
							<el-checkbox v-model="formData.checked">同意《用户协议》和《隐私政策》</el-checkbox>
						</el-form-item>
						<el-form-item class="main-login-form-button">
							<div class="button_flex" @click="mobileLogin('mobileRuleForm')">登录<span class="line"></span>注册</div>
						</el-form-item>
					</el-form>
				</div>
				<!-- 账号登录 -->
				<div class="main-login-form" v-show="activeName == 'account'">
					<el-form :model="formData" :rules="accountRules" ref="ruleForm"  >
						<el-form-item prop="account" :show-message="false">
							<el-input  v-model="formData.account" placeholder="账号"></el-input>
						</el-form-item>
						<el-form-item  prop="password" :show-message="false">
							<el-input type="password" autocomplete="off" v-model="formData.password" placeholder="密码"></el-input>
							<span class="right_part"><router-link to="/reset">忘记密码</router-link></span>
						</el-form-item>
						<el-form-item  prop="vercode" v-if="shop_pc_reception_login == 1" :show-message="false">
							<el-input autocomplete="off" v-model="formData.vercode" placeholder="验证码" maxlength="4"></el-input>
							<span class="right_part"><img :src="captcha.img" mode class="captcha" @click="getCaptcha" /></span>
						</el-form-item>
						<el-form-item class="main-login-form-checkbox tips" >
							<el-checkbox v-model="formData.checked">同意《用户协议》和《隐私政策》</el-checkbox>
						</el-form-item>
						<el-form-item class="main-login-form-button">
							<el-button @click="accountLogin('ruleForm')">登录</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mobileCode, registerConfig } from "@/api/auth/login"
import { adList, captcha, captchaConfig } from "@/api/website"
import { wechatLogin, checkWechatLogin, wechatLoginCode} from "@/api/resource"
export default {
	data() {
		var isMobile = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("手机号不能为空"))
			} else {
				const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

				if (reg.test(value)) {
					callback()
				} else {
					callback(new Error("请输入正确的手机号"))
				}
			}
		}
		return {
			formData: {
				account: "",
				password: "",
				vercode: "",
				mobile: "",
				dynacode: "",
				key: "",
				checked: true,
				autoLoginRange: 7
			}, // 表单数据
			activeName:'code',// tab切换
			captcha: {
				id: "",
				img: ""
			}, // 验证码
			dynacodeData: {
				seconds: 30,
				timer: null,
				codeText: "获取动态码",
				isSend: false
			}, // 动态码
			isSub: false, // 提交防重复
			registerConfig: {
				is_enable: 1
			},
			mobileRules: { // 短信登录
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "请输入短信动态码",
					trigger: "blur"
				}]
			},//账号登录
			accountRules: {
				account: [{
					required: true,
					message: "请输入登录账号",
					trigger: "blur"
				}],
				password: [{
					required: true,
					message: "请输入登录密码",
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}]
			},
			
			loadingAd: true,
			adList: [],
			shop_pc_reception_login:0,
			backgroundColor: '',
			wechat_qrcode:'',
			wx_key:'',//微信扫码登录key
			expire_time:'',//扫码过期时间
			qrcodeData: {
				time: 0,
				timer: 0,
			},
			qrcode_status:false
		}
	},
	created() {
		// this.getWechatLoginImg();
		this.getCaptcha();
		this.getRegisterConfig();
		this.getCaptchaConfig();
		this.getWechatLoginImg();
	},
	watch: {
		"formData.dynacode":{
			handler(newValue,oldValue){
				if(newValue.length==4 && this.formData.mobile){
					this.mobileLogin('mobileRuleForm');
				}
			},
			immediate:true,
			deep: true
		},
		"dynacodeData.seconds": {
			handler(newValue, oldValue) {
				if (newValue == 0) {
					clearInterval(this.dynacodeData.timer)
					this.dynacodeData = {
						seconds: 30,
						timer: null,
						codeText: "获取动态码",
						isSend: false
					}
				}
			},
			immediate: true,
			deep: true
		},
		
	},
	methods:{
		// 检测是否扫码
		checkLogin() {
			this.qrcodeData.time += 2;
			if (this.qrcodeData.time > this.expire_time) {
				clearInterval(this.qrcodeData.timer);
				return;
			};
			var data = {
				key: this.wx_key
			};
			this.$store
				.dispatch("member/check_wechat_login", data)
				.then(res => {
					if(res.data.token != undefined){
						this.$message({
							message: "登录成功！",
							type: "success"
						})
						clearInterval(this.qrcodeData.timer);
						if (this.$route.query.redirect) {
							this.$router.push(this.$route.query.redirect)
						} else {
							this.$router.push('/member/index')
						}
					}else{
						//注册
						this.qrcode_status = true;
					}
					
				})
				.catch(err => {
					this.isSub = false
				})
			
			
		},
		getWechatLoginImg(){
			wechatLoginCode()
			.then(res=>{
				if(res.code>=0){
					this.wechat_qrcode = res.data.qrcode;
					this.wx_key = res.data.key;
					this.expire_time = res.data.expire_time;
				}
			})
		},
		sendMobileCode(formName) {
			if (this.dynacodeData.seconds != 30) return
			this.$refs[formName].clearValidate("dynacode")
			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					this.$message.error(valid);
					return false;
				}else{
					if(this.shop_pc_reception_login == 1){
						this.$refs[formName].validateField("vercode", valid => {
							if (!valid) {
								mobileCode({
										mobile: this.formData.mobile,
										captcha_id: this.captcha.id,
										captcha_code: this.formData.vercode
									})
									.then(res => {
										if (res.code >= 0) {
											this.formData.key = res.data.key
											if (this.dynacodeData.seconds == 30 && this.dynacodeData.timer == null) {
												this.dynacodeData.timer = setInterval(() => {
													this.dynacodeData.seconds--
													this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
												}, 1000)
											}
										}
									})
									.catch(err => {
										this.$message.error(err.message)
									})
							} else {
								this.$message.error(valid)
								return false
							}
						})
					}else{
						mobileCode({
								mobile: this.formData.mobile,
								captcha_id: this.captcha.id,
								captcha_code: this.formData.vercode
							})
							.then(res => {
								if (res.code >= 0) {
									this.formData.key = res.data.key
									if (this.dynacodeData.seconds == 30 && this.dynacodeData.timer == null) {
										this.dynacodeData.timer = setInterval(() => {
											this.dynacodeData.seconds--
											this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
										}, 1000)
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message)
							})
					}
				}
			})
		},
		mobileLogin(formName,isWechat=false) {
			this.$refs[formName].validate((valid,fun) => {
				if (valid) {
					if(!this.formData.checked){
						this.$message.error('请阅读并同意《用户协议》和《隐私政策》')
						return;
					}
					if(!this.formData.key){
						this.$message.error('请获取短信验证码')
						return;
					}
					var data = {
						mobile: this.formData.mobile,
						key: this.formData.key,
						code: this.formData.dynacode
					}
					if (this.captcha.id != "") {
						data.captcha_id = this.captcha.id
						data.captcha_code = this.formData.vercode
					}
					if (this.isSub) return
					this.isSub = true
					if(isWechat){
						data.login_code_key = this.wx_key;
						this.$store
							.dispatch("member/wechat_login", data)
							.then(res => {
								if (res.code >= 0) {
									this.$message({
										message: "登录成功！",
										type: "success"
									})
									clearInterval(this.qrcodeData.timer);
									if (this.$route.query.redirect) {
										this.$router.push(this.$route.query.redirect)
									} else {
										this.$router.push('/member/index')
									}
								} else {
									this.isSub = false
									this.getCaptcha()
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.isSub = false
								this.$message.error(err.message)
								this.getCaptcha()
							})
					}else{
						this.$store
							.dispatch("member/mobile_login", data)
							.then(res => {
								if (res.code >= 0) {
									this.$message({
										message: "登录成功！",
										type: "success"
									})
									clearInterval(this.qrcodeData.timer);
									if (this.$route.query.redirect) {
										this.$router.push(this.$route.query.redirect)
									} else {
										this.$router.push({
											name: "member"
										})
									}
								} else {
									this.isSub = false
									this.getCaptcha()
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.isSub = false
								this.$message.error(err.message)
								this.getCaptcha()
							})
					}
					
				} else {
					var fail = [];
					for (let key in fun) {
						fail.push(fun[key][0].message);
					}
					this.$message.error(fail[0]);
					return false
				}
			})
		},
		accountLogin(formName) {
			this.$refs[formName].validate((valid,fun) => {
				if (valid) {
					if(!this.formData.checked){
						this.$message.error('请阅读并同意《用户协议》和《隐私政策》')
						return;
					}
					var data = {
						username: this.formData.account,
						password: this.formData.password
					}
		
					if (this.captcha.id != "") {
						data.captcha_id = this.captcha.id
						data.captcha_code = this.formData.vercode
					}
		
					if (this.formData.checked) {
						data.autoLoginRange = this.formData.autoLoginRange
					}
		
					if (this.isSub) return
					this.isSub = true
		
					this.$store
						.dispatch("member/login", data)
						.then(res => {
							if (res.code >= 0) {
								this.$message({
									message: "登录成功！",
									type: "success"
								})
								clearInterval(this.qrcodeData.timer);
								if (this.$route.query.redirect) {
									const a = this.$route.query.redirect
									const b = this.$route.query
									this.$router.push(this.$route.query.redirect)
								} else {
									this.$router.push({
										name: "member"
									})
								}
							} else {
								this.isSub = false
								this.getCaptcha()
								this.$message({
									message: res.message,
									type: "warning"
								})
							}
						})
						.catch(err => {
							this.isSub = false
							this.$message.error(err.message)
							this.getCaptcha()
						})
				} else {
					var fail = [];
					for (let key in fun) {
						fail.push(fun[key][0].message);
					}
					this.$message.error(fail[0]);
					return false
				}
			})
		},
		getCaptchaConfig() {
			captchaConfig()
			.then(res => {
				if(res.code >= 0) {
					this.shop_pc_reception_login = res.data.value.shop_pc_reception_login
				}
			})
			.catch(err => {
				this.$message.error(err.message)
			})
		},
		getRegisterConfig() {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value
						if (this.registerConfig.register.indexOf('username') != -1) {
							this.loginMode = 'account';
						} else {
							this.loginMode = 'mobile';
						}
					}
				})
				.catch(err => {
					console.log(err.message)
				})
		},
		getCaptcha() {
			captcha({
					captcha_id: this.captcha.id
				})
				.then(res => {
					if (res.code >= 0) {
						this.captcha.id = res.data.id
						this.captcha.img = res.data.img
						this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},
		handleClick(mode) {
			this.activeName = mode;
			this.$refs.mobileRuleForm.clearValidate();
			this.$refs.ruleForm.clearValidate();
			if(mode=='chat'){
				// this.getWechatLoginImg();
				this.qrcodeData.timer = setInterval(() => {this.checkLogin()}, 2000);
			}else{
				clearInterval(this.qrcodeData.timer);
			} 
		},
	}	
}
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc( 100vh - 43px );
	background-image: url("../../../assets/images/newImg/login_bg.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	.main {
		width: 554px;
		display: flex;
		flex-direction: column;
		.main-title {
			font-size: 38px;
			color: #fff;
			text-align: center;
			font-weight: 800;
			line-height: 53px;
			span{
				margin-right: 20px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.main-content {
			margin-top: 61px;
			padding: 76px 68px 80px;
			&.chat{
				padding: 76px 68px 86px;
			}
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.main-content-box {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				padding: 0 11px;
				border-bottom: solid 1px rgba(48, 55, 61, 0.20);
				box-sizing: border-box;
				.main-content-item {
					font-size: 19px;
					color: #30373D;
					line-height: 23px;
					padding-bottom: 25px;
					cursor: pointer;
				}
				.main-content-border {
					width: 1px;
					height: 15px;
					background-color: rgba(48, 55, 61, 0.20);
				}
			}

			
			.main-login-form{
				margin-top: 30px;
				:deep(.el-form-item__error){
					padding-left: 15px;
					color: red;
				}
				:deep(.el-input__inner){
					border: none;
					background-color: #F7F8FB;
					border-radius: 3px;
					height: 47px;
					line-height: 47px;
					text-align: center;
					font-size: 13px;
					font-family: "PingFang SC";
				}	
				:deep(.el-input__inner::-webkit-input-placeholder){
					color: #999;
					font-size: 13px;
					font-family: "PingFang SC";
				}
				:deep(.el-form-item){
					margin-bottom: 30px;
				}
				.main-login-form-checkbox{
					text-align: center;
					line-height: 1;
					margin-bottom: 20px;
					&.tips{
						margin-top: 69px;
					}
					:deep(.el-checkbox){
						display: flex;
						align-items: center;
						justify-content: center;
					}
					:deep(.el-checkbox__input){
						display: flex;
						align-items: center;
						&.is-checked{
							.el-checkbox__inner{
								border-color:#30373D;
								background-color: #30373D;
							}
						}
						&.is-focus{
							.el-checkbox__inner{
								border-color:#30373D;
							}
						}
					}
					:deep(.el-checkbox__inner){
						&:hover{
							border-color:#30373D;
						}
					}
					:deep(.el-form-item__content){
						line-height: 1;
					}
					:deep(.el-checkbox__label){
						color: #999;
						font-size: 13px;
						line-height: 15px;
					}
				}
				.main-login-form-button{
					text-align: center;
					margin-bottom: 0px;
					background-color: #30373D;
					border-radius: 3px;
					.button_flex{
						display: flex;
						align-items: center;
						font-size: 19px;
						line-height: 23px;
						padding: 12px 0;
						color:#fff;
						justify-content: center;
						cursor: pointer;
						.line{
							display: block;
							width: 1px;
							height: 15px;
							background-color: rgba(255, 255, 255, 0.20);
							margin: 0 15px;
						}
					}
					.el-button{
						background-color: transparent;
						font-size: 19px;
						line-height: 23px;
						color:#fff;
						width: 100%;
						border: none;
					}
				}
				.captcha{
					cursor: pointer;
				}
			}
			.main-login-chat{
				margin-top: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.qrcode_area{
					width:100%;
					.qrcode_tips{
						text-align: center;
						line-height: 19px;
					}
					.main-login-form{
						margin-top: 20px;
					}
				}
				.code-img{
					width: 224px;
					height: 224px;
					border-radius: 3px;
					display: block;
				}
				.code-content{
					font-size: 13px;
					color: #999;
					text-align: center;
					line-height: 15px;
					margin-top: 30px;
				}
			}
		}
	}
}
.right_part{
	position: absolute;
	right: 10px;
	top: 50%;
	color: #30373d;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 13px;
	line-height: 15px;
	a{
		font-size: 13px;
		line-height: 15px;
		color: #30373d !important;
	}
}
.class-select {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		height: 3px;
		background-color: #30373D;
		width: 34px;
		border-radius: 2px;
		left: 50%;
		transform: translate(-50%,50%);
	}
}
</style>